import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"
import dsm from "../utils/dsm"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"

import {
  PageSectionTitle,
  I2BronzeText,
  BodyText,
  BodyBold,
} from "../components/textComponents"

import { PageSectionButton } from "../components/interactiveComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

var ExpItemCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 2rem;

  ${device.mobileL} {
    margin: 0.5rem 0 1.5rem;
  }

  ${device.tablet} {
    flex-direction: row;
    height: 7rem;
  }
`
var ExpLogoCont = styled.div`
  width: 45%;

  ${device.mobileL} {
    width: 35%;
  }

  ${device.mobileX} {
    width: 30%;
  }

  ${device.tablet} {
    margin: auto;
    width: 20%;
  }
`

var ExpDetailsCont = styled.div`
  margin: 0.75rem 0;

  ${device.tablet} {
    width: 68%;
    margin-left: 2%;
  }

  ${device.laptop} {
    width: 57%;
    margin-left: 2%;
    margin-right: 12%;
  }
`

var ExpCompanyCont = styled.div``

var ExpCompany = styled.h4`
  ${dsm.fonts.primary}
  color: ${dsm.color.brand.copper.dark};
  text-shadow: 0.1rem 0.1rem 0.3rem rgba(0,0,0,0.15);
  font-size: 1.25rem;
  font-weight: 750;
  margin: 0 0 0.25rem;

  ${device.mobileM} {
    font-size: 1.35rem;
    margin-bottom: 0.35rem;
  }
`
var ExpCompanyTitle = styled.div`
  ${dsm.fonts.primary};
  font-size: 1.25rem;
  font-weight: 750;
  margin-bottom: 0.3rem;

  ${device.mobileM} {
    font-size: 1.35rem;
    margin-bottom: 0.4rem;
  }
`
var ExpDesc = styled.div`
  font-size: 1.15rem;

  ${device.mobileM} {
    font-size: 1.25rem;
  }
`

function IndustryExperience() {
  var data = useStaticQuery(graphql`
    query {
      indExpTopImg: file(
        relativePath: { eq: "I2_Optimization_Bi_Fuel_Horsepower_NG.jpeg" }
      ) {
        ...fluidImageSVG
      }
      laporteLogo: file(
        relativePath: { eq: "i2_Optimization_Laporte_Production_Services.png" }
      ) {
        ...fluidImage
      }
      fmcLogo: file(
        relativePath: { eq: "i2_Optimization_FMC_Technologies.png" }
      ) {
        ...fluidImage
      }
      cathedralLogo: file(
        relativePath: { eq: "i2_Optimization_Cathedral_Energy.png" }
      ) {
        ...fluidImage
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Industry Experience"
        description="Matt Deady - i2 Optimization. Operations management experience at Laporte Production Services, FMC Technologies, and Cathedral Energy Services."
      />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.indExpTopImg.sharp.fluid}
          alt="i2 Optimization - Natural Gas for Bi-Fuel Horsepower"
        />
        <PageSectionTitle>
          Industry <I2BronzeText>Experience</I2BronzeText>
        </PageSectionTitle>
      </PageTopFullSizeImgCont>

      <SectionContentContainer>
        <ExpItemCont>
          <ExpLogoCont>
            <Image
              style={{ height: "inherit" }}
              objectFit="cover"
              fluid={data.laporteLogo.sharp.fluid}
              alt="i2 Optimization Experience, Laporte Production - Natural Gas Bi-Fuel Horsepower"
            />
          </ExpLogoCont>
          <ExpDetailsCont>
            <ExpCompanyCont>
              <ExpCompany>Laporte Production Services</ExpCompany>
              <ExpCompanyTitle>Operations Management</ExpCompanyTitle>
            </ExpCompanyCont>
            <ExpDesc>
              Providing Conditioned Natural Gas for Bi-Fuel Horsepower.
            </ExpDesc>
          </ExpDetailsCont>
        </ExpItemCont>

        <ExpItemCont>
          <ExpLogoCont>
            <Image
              style={{ height: "inherit" }}
              objectFit="cover"
              fluid={data.fmcLogo.sharp.fluid}
              alt="i2 Optimization Experience, FMC Technologies - Oil and Gas Fracking"
            />
          </ExpLogoCont>
          <ExpDetailsCont>
            <ExpCompanyCont>
              <ExpCompany>FMC Technologies</ExpCompany>
              <ExpCompanyTitle>Operations Management</ExpCompanyTitle>
            </ExpCompanyCont>
            <ExpDesc>
              Resource allocation, business development, personnel management.
            </ExpDesc>
          </ExpDetailsCont>
        </ExpItemCont>

        <ExpItemCont>
          <ExpLogoCont>
            <Image
              style={{ height: "inherit" }}
              objectFit="cover"
              fluid={data.cathedralLogo.sharp.fluid}
              alt="i2 Optimization Experience, Cathedral Energy - Oil and Gas Optimization"
            />
          </ExpLogoCont>
          <ExpDetailsCont>
            <ExpCompanyCont>
              <ExpCompany>Cathedral Energy Services</ExpCompany>
              <ExpCompanyTitle>Operations Management</ExpCompanyTitle>
            </ExpCompanyCont>
            <ExpDesc>Operating Procedure Development/Implementation. </ExpDesc>
          </ExpDetailsCont>
        </ExpItemCont>

        <BodyText>
          <BodyBold>
            <I2BronzeText>And More...</I2BronzeText>
          </BodyBold>
        </BodyText>

        <BodyText>
          We are a <BodyBold>results-driven</BodyBold> company committed to{" "}
          <BodyBold>enhancing your productivity</BodyBold> through the
          implementation of <BodyBold>innovative solutions</BodyBold> applied to{" "}
          <BodyBold>over 20 years of industry insights.</BodyBold>
        </BodyText>

        <PageSectionButton to="/contact-us/">Contact Us</PageSectionButton>
      </SectionContentContainer>
    </Layout>
  )
}

export default IndustryExperience
